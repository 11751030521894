<aside [ngStyle]="{ display: menuOpen ? 'block' : 'none' }"
  class="animate__animated animate__fadeIn w-52 h-full bg-white p-2 min-h-[94.2vh] absolute z-[900] xl:static shadow-lg xl:!block">
  <ul class="mt-8 mb-16">
    @for (route of routenavigators; track route) {
    <li>
      <button [routerLink]="route.url" brnToggle class="w-[95%] h-14 flex items-center justify-start my-1"
        [isActive]="route.state" hlm>
        <hlm-icon class="w-5 h-5 mr-2" [name]="route.icon_name"></hlm-icon>
        <span>{{ route.nombre }}</span>
      </button>
    </li>
    }

    <li>
      <button routerLink="/logout" hlmToggle class="toogle w-[95%] h-14 flex items-center justify-start my-1 sm:hidden"
        hlm>
        <hlm-icon class="w-5 h-5 mr-2" name="lucideBell"></hlm-icon>
        <span>Notificaciones</span>
      </button>
    </li>
    <li>
      <button (click)="logOffTheWeb()" routerLink="/logout" hlmToggle
        class="toogle w-[95%] h-14 items-center justify-start my-1 sm:hidden flex" hlm>
        <hlm-icon class="w-5 h-5 mr-2" name="lucideXCircle"></hlm-icon>
        <span>Cerrar Sesión</span>
      </button>
    </li>
  </ul>
  @if (_accessByRole(["ROLE_CLIENTE_CHECKOUT"])) {
  <button routerLink="/checkout/close" hlmBtn
    class="button w-[95%] h-9 flex items-center justify-center bg-[#ED6C43] text-white">
    Cierre de caja
  </button>
  }
</aside>
